import useTranslation from 'next-translate/useTranslation';

import { Box, Typography } from '@mui/material';
import type { SxProps } from '@mui/material';

import type { EventStatus } from 'types/event';
import type { SportType } from 'types/scoreboardData';

interface StatusChipProps {
  type: 'status';
  value: EventStatus;
  customSx?: SxProps;
}

interface SportTypeChipProps {
  type: 'sportType';
  value: SportType;
  customSx?: SxProps;
}

const EventChip = ({
  type,
  value,
  customSx,
}: StatusChipProps | SportTypeChipProps) => {
  const { t } = useTranslation('event');

  const eventStatusColors = {
    register: 'primaryColor.500',
    scheduled: 'secondaryColor.2',
    progress: '#BF0C0E',
    end: '#9393A2',
  };

  const commonSx = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    px: 1,
    borderRadius: '9px',
    color: 'white',
  };

  return type === 'status' ? (
    <Box
      sx={{
        ...commonSx,
        bgcolor: eventStatusColors[value],
        ...customSx,
      }}
    >
      <Typography variant="chip" sx={{ fontSize: { xs: '11px', sm: '13px' } }}>
        {t(`statusList.${value}`)}
      </Typography>
    </Box>
  ) : (
    <Box
      sx={{
        ...commonSx,
        bgcolor: '#60606F',
        ...customSx,
      }}
    >
      <Typography variant="chip" sx={{ fontSize: { xs: '11px', sm: '13px' } }}>
        {t(`sportType.${value}`)}
      </Typography>
    </Box>
  );
};

export default EventChip;
