import type { MouseEvent} from 'react';
import { useState } from 'react';

import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useRouter } from 'next/router';

import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import MoreIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/ShareOutlined';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';

import EventChip from 'components/atoms/EventChip';
import { NextLinkComposed } from 'components/atoms/Link';

import useCheckDevice from 'hooks/useCheckDevice';
import useClipboardCopy from 'hooks/useClipboardCopy';

import type { EventListData } from 'types/event';

import { dayFormatter } from 'utils/dayFormatter';
import { eventThumbnailPicker } from 'utils/defaultThumbnailPicker';

const EventCard = ({
  isMyChannel,
  event,
  usedPage,
  handleClickDeleteBtn,
}: {
  isMyChannel: boolean;
  event: EventListData;
  usedPage: 'main' | 'channel' | 'event' | 'search';
  handleClickDeleteBtn?: (event: EventListData) => void;
}) => {
  const { t } = useTranslation('event');
  const { shareUrl } = useClipboardCopy();
  const { locale } = useRouter();
  const {
    palette: { mode },
  } = useTheme();
  const { isPc, isMobile } = useCheckDevice();

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );

  const {
    id: eventId,
    status,
    thumbnailName,
    thumbnailUrl,
    title,
    from,
    to,
    sportType,
    registerStart,
    registerEnd,
  } = event;

  const eventStatusColors = {
    register: 'primaryColor.500',
    scheduled: 'secondaryColor.2',
    progress: '#BF0C0E',
  };

  const menuList = [
    {
      id: 'edit',
      classForGTM: 'gtm-event-edit-btn',
      isShow: isMyChannel,
      icon: <EditIcon />,
      text: t('btn.edit'),
    },
    {
      id: 'share',
      classForGTM: 'gtm-event-share-btn',
      isShow: true,
      icon: <LinkIcon sx={{ transform: 'rotate(-43deg)' }} />,
      text: t('btn.share'),
      onclick: (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setMenuAnchorEl(null);
        handleClickShareBtn();
      },
    },
    {
      id: 'delete',
      classForGTM: 'gtm-event-delete-btn',
      isShow: isMyChannel,
      icon: <DeleteIcon />,
      text: t('btn.delete'),
      onclick: (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setMenuAnchorEl(null);
        if (handleClickDeleteBtn) handleClickDeleteBtn(event);
      },
    },
  ];

  const handleClickShareBtn = () => {
    shareUrl(`${window.location.origin}/${locale}/event/${eventId}`);
  };

  const calculateDday = (status: 'register' | 'scheduled') => {
    const TODAY_TIME = new Date().getTime();
    let timeGap = 0;
    if (status === 'register' && registerEnd) {
      const registerEndTime = new Date(registerEnd).getTime();
      timeGap = registerEndTime - TODAY_TIME;
    }

    if (status === 'scheduled') {
      if (registerStart) {
        const registerStartTime = new Date(registerStart).getTime();
        timeGap = registerStartTime - TODAY_TIME;
      }
      if (timeGap <= 0 && from) {
        const fromTime = new Date(from).getTime();
        timeGap = fromTime - TODAY_TIME;
      }
    }

    const dDay = Math.ceil(timeGap / (1000 * 60 * 60 * 24));
    return `D-${dDay}`;
  };

  return (
    <Box
      className="gtm-event-detail-btn"
      key={eventId}
      component={NextLinkComposed}
      to={`/event/${eventId}`}
      sx={{
        borderRadius: '6px',
        border: '1px solid',
        borderColor: mode === 'dark' ? 'gray.100' : '#E5E5E9',
        cursor: 'pointer',
        overflow: 'hidden',
        minWidth:
          usedPage === 'main'
            ? { xs: '210px', sm: '250px', lg: '300px' }
            : '100%',
        width: '100%',
        transition: 'transform .3s, box-shadow .3s',
        scrollSnapAlign: 'start',

        ...(isPc && {
          ':hover': {
            transform: 'translateY(-8px)',
            boxShadow:
              mode === 'dark'
                ? '0px 8px 16px rgba(120, 120, 120, 0.25)'
                : '0px 8px 16px rgba(0, 0, 0, 0.25)',
          },
        }),
      }}
    >
      <Box sx={{ position: 'relative' }}>
        {(status === 'register' && registerEnd) ||
        (status === 'scheduled' && from) ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: { xs: 'auto', sm: '20px' },
              left: { xs: '20px', sm: 'auto' },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: { xs: '36px', sm: '50px' },
              height: { xs: '28px', sm: '37px' },
              bgcolor: eventStatusColors[status],
              filter: 'drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25))',
              zIndex: 2,
              '&:before': {
                position: 'absolute',
                top: { xs: '28px', sm: '37px' },
                left: 0,
                width: 0,
                height: 0,
                content: "''",
                borderTopWidth: { xs: '12px', sm: '18px' },
                borderTopStyle: 'solid',
                borderTopColor: eventStatusColors[status],
                borderLeft: {
                  xs: '18px solid transparent',
                  sm: '25px solid transparent',
                },
                borderRight: {
                  xs: '18px solid transparent',
                  sm: '25px solid transparent',
                },
              },
            }}
          >
            <Typography
              variant="chip"
              sx={{
                fontWeight: 700,
                color: 'white',
                fontSize: { xs: '10px', sm: 'inherit' },
                pt: { xs: '6px', sm: '9px' },
              }}
            >
              {calculateDday(status)}
            </Typography>
          </Box>
        ) : null}

        <Box
          sx={{
            position: 'relative',
            aspectRatio: '3 / 4',
            borderRadius: '6px',
            '&:before': {
              content: "''",
              zIndex: 1,
              position: 'absolute',
              bottom: '-4px',
              left: 0,
              right: 0,
              height: '100%',
              borderRadius: '6px',
              background:
                'linear-gradient(180deg, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 1) 100%)',
            },
          }}
        >
          <Image
            src={thumbnailUrl ?? eventThumbnailPicker(sportType || 'universal')}
            layout="fill"
            placeholder="blur"
            blurDataURL="data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8bwEAAjwBOdoVrI8AAAAASUVORK5CYII"
            objectFit="cover"
            alt={thumbnailName ?? ''}
            style={{ borderRadius: '6px' }}
          />
        </Box>

        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 2,
            pl: { xs: '14px', sm: '20px', lg: 3 },
            pr: { xs: 0, sm: 2, lg: 2 },
            pb: { xs: 1, sm: 2, lg: '20px' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: { xs: 0.5, sm: 1 },
            }}
          >
            <EventChip type="status" value={status} customSx={{ mr: 0.5 }} />
            {sportType !== null ? (
              <EventChip type="sportType" value={sportType} />
            ) : null}
          </Box>

          <Typography
            variant="h4Bold"
            sx={{
              mb: { xs: '4px', sm: 1 },
              pr: { xs: 1, sm: 0 },
              display: '-webkit-box',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflowWrap: 'anywhere',
              fontSize: { xs: '14px', sm: '20px' },
              color: 'white',
            }}
          >
            {title}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <DateRangeIcon
              sx={{
                display: { xs: 'none', sm: 'block' },
                mr: 1,
                width: '18px',
                height: '18px',
                color: 'deprecatedGray.60',
              }}
            />
            {from ? (
              <Typography
                variant="body2"
                sx={{
                  color: 'deprecatedGray.60',
                  fontSize: {
                    xs: usedPage === 'main' ? '11px' : '10px',
                    sm: '14px',
                  },
                }}
              >
                {`${dayFormatter(from, 'YYYY.MM.DD ', {
                  locale,
                  isZuluTime: true,
                })} ${
                  to !== null
                    ? `~ ${dayFormatter(to, 'YYYY.MM.DD ', {
                        locale,
                        isZuluTime: true,
                      })}`
                    : ''
                } `}
              </Typography>
            ) : (
              <Typography variant="body3" sx={{ color: 'gray.700' }}>
                {t('errorMsg.emptySchedule')}
              </Typography>
            )}
          </Box>
        </Box>
        <IconButton
          className="gtm-event-share-btn"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (isMyChannel) {
              setMenuAnchorEl(e.currentTarget);
            } else {
              handleClickShareBtn();
            }
          }}
          sx={{
            position: 'absolute',
            zIndex: 10,
            right: { xs: '4px', sm: '8px' },
            ...(isMobile ? { top: '2px' } : { bottom: '16px' }),
            color: 'white',
          }}
        >
          {isMyChannel ? <MoreIcon /> : <ShareIcon />}
        </IconButton>
        <Menu
          id={
            isMyChannel
              ? 'gtm-myEvent-kebab-menu-area'
              : 'gtm-event-kebab-menu-area'
          }
          open={Boolean(menuAnchorEl)}
          anchorEl={menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
          onClick={(e) => e.stopPropagation()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            '& .MuiPaper-root': {
              boxShadow: '1px 1px 1px 0px 0px black',
            },
          }}
          MenuListProps={{
            sx: {
              '& > li:hover, & > a:hover': {
                bgcolor:
                  mode === 'dark' ? 'primaryColor.800' : 'primaryColor.50',
              },
            },
          }}
        >
          {menuList.map(({ id, classForGTM, isShow, icon, text, onclick }) => {
            if (!isShow) return null;

            return (
              <MenuItem
                key={id}
                className={classForGTM}
                component={id === 'edit' ? NextLinkComposed : 'li'}
                to={
                  id === 'edit'
                    ? { pathname: `/event/edit/${eventId}` }
                    : undefined
                }
                onClick={onclick}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText>{text}</ListItemText>
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    </Box>
  );
};

export default EventCard;
