import { Box, Skeleton } from '@mui/material';

import useCheckDevice from 'hooks/useCheckDevice';

const EventCardSkeleton = ({ usedPage }: { usedPage: 'main' | 'default' }) => {
  const { isMobile } = useCheckDevice();

  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: 1.5,
        aspectRatio: '3 / 4',
        width:
          usedPage === 'main'
            ? { xs: '210px', sm: '250px', lg: '300px' }
            : '100%',
        height:
          usedPage === 'main'
            ? { xs: '280px', sm: '330px', lg: '400px' }
            : '100%',
      }}
    >
      <Skeleton
        variant="rounded"
        sx={{
          borderRadius: 1.5,
          aspectRatio: '3 / 4',
          width:
            usedPage === 'main'
              ? { xs: '210px', sm: '250px', lg: '300px' }
              : '100%',
          height:
            usedPage === 'main'
              ? { xs: '280px', sm: '330px', lg: '400px' }
              : '100%',
        }}
      />

      <Skeleton
        variant="rounded"
        sx={{
          position: 'absolute',
          bottom: { xs: 52, sm: 72 },
          left: { xs: 14, sm: 28 },
          borderRadius: 5,
          width: '40px',
          height: '14px',
        }}
      />

      <Skeleton
        variant="text"
        sx={{
          position: 'absolute',
          bottom: { xs: 26, sm: 46 },
          left: { xs: 14, sm: 28 },
          borderRadius: 1.5,
          width:
            usedPage === 'main'
              ? { xs: '110px', sm: '150px', lg: '200px' }
              : { xs: '70%', sm: '60%' },
          height: '24px',
        }}
      />

      <Skeleton
        variant="text"
        sx={{
          position: 'absolute',
          bottom: { xs: 8, sm: 28 },
          left: { xs: 14, sm: 28 },
          borderRadius: 1.5,
          width:
            usedPage === 'main'
              ? { xs: '110px', sm: '150px', lg: '200px' }
              : { xs: '70%', sm: '60%' },
          height: '18px',
        }}
      />

      <Skeleton
        variant="circular"
        sx={{
          position: 'absolute',
          right: 20,
          width: '30px',
          height: '30px',
          ...(isMobile ? { top: '24px' } : { bottom: '24px' }),
        }}
      />
    </Box>
  );
};

export default EventCardSkeleton;
